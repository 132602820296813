<template>
  <div class="standards">
    <div class="myorderTab">
      <div class="flexWrp">
        <div :class="['flexItem',statusIndex == 1 ? 'active2' : '']" @click="choose(1)">
          <span class="fz30">仓库入库标准</span>
          <div v-if="statusIndex == 1" class="line_b"></div>
        </div>
        <div :class="['flexItem',statusIndex == 2 ? 'active2' : '']" @click="choose(2)">
          <span class="fz30">仓库计费规则</span>
          <div v-if="statusIndex == 2" class="line_b"></div>
        </div>
      </div>
    </div>
    <div v-if="statusIndex == 1" class="fz28 f888 tl p30 text-color">
      <h5 class="title">一、禁止收寄</h5>
      <p class="icon_m">禁止收取易燃、易爆、易腐蚀、有毒、放射性及违法物品。</p>
      <h5 class="title">二、限制收寄</h5>
      <p>化工类：除日用化工品外(如牙膏、洗面奶、洗洁精、化妆品)，其它化工类物品需提供安全属性证明材料，确认安全后，将证明材料随货寄出。若需要通过航空发运的，需提供<span
          class="icon_m">《鉴定报告书》</span>后方可入库。</p>
      <h5 class="title">二、限制收寄</h5>
      <p>生产日期：必须要有生产日期且有效期超过保质期的三分之一</p>
      <p>外包装：包装完整，没有破损、潮湿、漏液，箱子承重变形情况</p>
      <p>条形码：条形码正确、与实物一致</p>
      <p>标签：标签完整，标签粘贴平整（无小气泡)</p>
      <p>基础信息：品名、成分、公司信息、执行标准、生产许可证、产地、净含量、储存方法、合格字样等完整</p>
      <h5 class="title">四：托盘标准</h5>
      <p>托盘标准为：1.2*1*1.6</p>
    </div>
    <div v-if="statusIndex == 2" class="fz28 f888 tl p30 text-color">
      <div class="bg-fff">
        <div class="flex_m">
          <div class="flex1">
            <div class="t-tr bg-w">
              <div class="th" v-for="(thName,thIdx) in tHeadData">
                {{thName.name}}
              </div>
            </div>
            <div class="t-tr" v-for="(item,tdIdx) in tBodyData">
              <div class="td" v-for="(thName,thIdx) in tHeadData">
                {{ item[thName.val] ? item[thName.val] : '/'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'finished',
    data() {
      return {
        statusIndex: 1,
        tHeadData: [
          { name: '项目', val: 'name' },
          { name: '是否增值服务', val: 'isIncrement' },
          { name: '云配白云仓', val: 'ware1' },
          { name: '京东博展仓', val: 'ware2' },
          { name: '广州诚通仓', val: 'ware3' },
          { name: '单位', val: 'unit' }
        ], //已选商品的表头
        tBodyData: [
          { name: '仓租费', isIncrement: '否', ware1: '5', ware2: '4', ware3: '5', unit: '元/托/天' },
          { name: '入仓装卸费', isIncrement: '否', ware1: '43', ware2: '40', ware3: '43', unit: '元/吨' },
          { name: '货物上架', isIncrement: '是', ware1: '', ware2: '22', ware3: '', unit: '元/方' },
          { name: '整板拣货', isIncrement: '是', ware1: '5', ware2: '5', ware3: '5', unit: '元/托' },
          { name: '按箱拣货', isIncrement: '是', ware1: '3', ware2: '3', ware3: '3', unit: '元/箱' },
          { name: '散货出库', isIncrement: '是', ware1: '43', ware2: '40', ware3: '43', unit: '元/吨' },
          { name: '退货', isIncrement: '是', ware1: '', ware2: '3', ware3: '', unit: '元/件' },
          { name: '贴标', isIncrement: '是', ware1: '1', ware2: '2', ware3: '2', unit: '元/件' },
          { name: '缠绕膜', isIncrement: '是', ware1: '158', ware2: '', ware3: '', unit: '元/方' },
          { name: '翻板', isIncrement: '是', ware1: '72', ware2: '', ware3: '', unit: '元/方' },
          { name: '再包装', isIncrement: '是', ware1: '', ware2: '36', ware3: '', unit: '元/件' }
        ]
      };
    },
    mounted() {

    },
    methods: {
      choose(e) {
        this.statusIndex = e
      },
    }
  };
</script>
<style scoped>
  .myorderTab {
    height: 88px;
    line-height: 88px;
    font-size: 30px;
    text-align: center;
    box-sizing: content-box;
  }

  .flexWrp {
    display: flex;
  }

  .myorderTab .flexItem {
    position: relative;

    flex: 1
  }

  .active2 span{
    color: #0A8DE4;
  }
  .flexItem span{
    cursor: pointer;
  }
  .flexItem text {
    color: #555;
  }

  .flexItem .line_b {
    background: #0A8DE4;
    width: 80px;
    height: 4px;
    position: absolute;
    left: 50%;
    bottom: 8px;
    transform: translateX(-50%);
  }

  .myorderTab .flexItem.active2 text {
    color: #B93A13;
  }
  .icon_m{
    color: #B93A13;
  }

  .orderStatus-wrap {
    height: 81px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DBDCDB;
  }

  view {
    color: #888 !important;
  }

  .title {
    margin: 5px 0 5px 0;
    color: #666;
  }

  p {
    display: block;
    line-height: 46px;
  }

  .table {
    border: 0px solid darkgray;
  }

  .t-tr {
    display: flex;
    width: 150%;
    justify-content: center;
    height: 70px;
    background-color: #fff;
    align-items: center;
    border-bottom: 1px solid #DBDCDB;
  }

  .bg-w {
    height: 65px;
  }

  .th,
  .td {
    flex: 1;
    justify-content: center;
    text-align: center;
  }

  .th {
    background-color: #E2EFF6;
    color: #666;
    display: flex;
    height: 65px;
    align-items: center;
  }

  .bg-fff {
    width: 100%;
    overflow-x: auto;
  }

  .warehouse {
    margin: 0 auto;
    max-width: 1200px;
  }

  @media screen and (min-width:1000px) {
    .t-tr{
      width:100%;
    }
  }
</style>